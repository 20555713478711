import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/system";

const CardWrapper = styled(Card)({
  background: "rgb(255 252 248)",
  marginBottom: 24,
});

const nutritionFacts = [
  // {
  //   label: "ROI Up To",
  //   value: "4.5",
  // },
  {
    label: "APY",
    value: "2,300",
  },
  {
    label: "Buy fee",
    value: 2,
  },
  {
    label: "Fridge fee",
    value: 2,
  },
  {
    label: "Invested Back",
    value: 3,
  },
];

export default function NutritionFacts() {
  return (
    <CardWrapper className="wrapper">
      <CardContent>
        <Typography variant="h5" borderBottom="6px solid" paddingBottom={1}>
          NutritionFacts:
        </Typography>
        <Box paddingTop={2}>
          {nutritionFacts.map((f) => (
            <Grid container key={f.label} justifyContent="space-between">
              <Typography variant="body1" gutterBottom>
                {f.label}
              </Typography>
              <Typography gutterBottom>{f.value}%</Typography>
            </Grid>
          ))}
        </Box>
        {/* <div style={{marginTop: "15px", fontSize: "18px", color: "blue"}}>
          <b>People have to Multiply 5 times before they claim.</b>
        </div> */}
      </CardContent>
    </CardWrapper>
  );
}
