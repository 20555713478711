import Grid from "@mui/material/Grid";
import { config } from "../../config";
import P from "../assets/P.png";

import tgIcon from "../assets/TGIcon.png";
import twIcon from "../assets/TWIcon.png";
import PP from "../assets/PP.png";
import B from "../assets/B.png";
import T from "../assets/T.png";
import N from "../assets/N.png";

export default function Footer() {
  return (
    <Grid container justifyContent="center" spacing={2} marginTop={4}>
      <Grid item>
        <a href="https://snowtrace.io/address/0x3D3b4a32C27eefbC0098846D7C0F8be37f52d6a2"target="" >
          <img src={PP} alt="" style={{width: '80px', height: '77px'}}/>
        </a>
      </Grid>
      <Grid item>
        <a href="https://t.me/chilliAVAX" target="__blank">
          <img src={T} alt="" width={75} height={77} />
        </a>
      </Grid>
      {/* <Grid item>
        <a href="" target="__blank">
          <img src={twIcon} alt="" width={48} height={48} />
        </a>
      </Grid> */}
    </Grid>
  );
}
