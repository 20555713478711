/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

// Rest of the code

import { useRef } from "react";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import { useLocation } from "react-router-dom";
import Web3 from "web3";

import PriceInput from "../../components/PriceInput";
import { useContractContext } from "../../providers/ContractProvider";
import { useAuthContext } from "../../providers/AuthProvider";
import { useEffect, useState } from "react";
import { config } from "../../config";
// import "../../index.css"
// import logoGif from "../../assets/logo.gif";
// import logoPng from "../../assets/logo.png";
import Connect from "./Connect";
// import AMPL4 from "../../assets/AMPL4.png";
import AMPL5 from "../../assets/AMPL5.png";
import s from "../../assets/s.png";




// import Gif from 'react-gif';

const CardWrapper = styled(Card)({
  background: "rgb(251 241 225)",
  marginBottom: 24,
});

const Wrapper = styled("div")(({ theme }) => ({
  maxWidth: 400,
  margin: "0 auto",
  marginBottom: "20px",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));


const Logo = styled("img")(({ theme }) => ({
  margin: "auto",
}));

const ButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    "> div": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

let timeout = null;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function BakeCard() {


  const { contract, wrongNetwork, getBnbBalance, fromWei, toWei, web3 } =
    useContractContext();
  const vidRef = useRef(null);
  const { address, chainId } = useAuthContext();
  const [contractBNB, setContractBNB] = useState(0);
  const [walletBalance, setWalletBalance] = useState({
    bnb: 0,
    beans: 0,
    rewards: 0,
  });
  const [bakeBNB, setBakeBNB] = useState(0);
  const [calculatedBeans, setCalculatedBeans] = useState(0);
  const [loading, setLoading] = useState(false);
  const [win, setWin] = useState(null);
  const query = useQuery();
  var theURL = "https://giphy.com/embed/RLj49U7KXsW9SmrLB6";



  // useEffect(() => {
  //   window.onload = () => {
  //     var iframeTag = document.querySelector("iframe");
  //     console.log('[Tag] = ', iframeTag);
  //     var winTag = iframeTag.contentWindow;
  //     console.log('[WinTag] = ', winTag)
  //     winTag.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');

  //     setWin(winTag);
  //     pauseVideo();
  //   }
  // }, []);

  // const playVideo = () => {
  //   if (win) {
  //     console.log('[123] = Play');
  //     win.postMessage('playVideo', theURL);
  //   }
  // }

  // const pauseVideo = () => {
  //   console.log('[Pause] = ', win);
  //   if (win) {
  //     console.log('[123] = Pause');
  //     win.postMessage('pauseVideo', theURL);
  //   }
  // }

  const fetchContractBNBBalance = () => {
    if (!web3 || wrongNetwork) {
      setContractBNB(0);
      return;
    }
    getBnbBalance(config.contractAddress).then((amount) => {
      setContractBNB(fromWei(amount));
    });
  };

  const fetchWalletBalance = async () => {
    if (!web3 || wrongNetwork || !address) {
      setWalletBalance({
        bnb: 0,
        beans: 0,
        rewards: 0,
      });
      return;
    }

    try {
      const [bnbAmount, beansAmount, rewardsAmount] = await Promise.all([
        getBnbBalance(address),
        contract.methods
          .getMyMiners(address)
          .call()
          .catch((err) => {
            console.error("myminers", err);
            return 0;
          }),
        contract.methods
          .beanRewards(address)
          .call()
          .catch((err) => {
            console.error("beanrewards", err);
            return 0;
          }),
      ]);
      setWalletBalance({
        bnb: fromWei(`${bnbAmount}`),
        beans: beansAmount,
        rewards: fromWei(`${rewardsAmount}`),
      });
    } catch (err) {
      console.error(err);
      setWalletBalance({
        bnb: 0,
        beans: 0,
        rewards: 0,
      });
    }
  };

  useEffect(() => {
    fetchContractBNBBalance();
  }, [web3, chainId]);

  useEffect(() => {
    fetchWalletBalance();
  }, [address, web3, chainId]);

  const onUpdateBakeBNB = (value) => {
    setBakeBNB(value);
  };

  const getRef = () => {
    const ref = Web3.utils.isAddress(query.get("ref"))
      ? query.get("ref")
      : "0x0000000000000000000000000000000000000000";
    return ref;
  };

  // var myVideo = document.querySelector("video1");


  const bake = async () => {
    if (wrongNetwork) {
      alert("Please connect to the correct network.");
      return;
    }
  
    if (!address) {
      alert("Please connect your wallet.");
      return;
    }
  
    console.log("Bake function called");
    setLoading(true);
  
    try {
      const iframe = document.getElementById("my-iframe");
      const win = iframe.contentWindow;
      win.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    } catch (err) {
      console.error("Failed to pause video:", err);
    }
  
    const ref = getRef();
  
    try {
      await contract.methods.buyEggs(ref).send({
        from: address,
        value: toWei(`${bakeBNB}`),
      });
      console.log("Transaction sent");
    } catch (err) {
      console.error("Transaction error:", err);
      alert("Transaction failed. Please check the console for more information.");
    }
  
    fetchWalletBalance();
    fetchContractBNBBalance();
    setLoading(false);
  };
  


  const stake90Days = function (){


    //When users click on 90 Days button, they will bake and stake for 90 days. 
    //They will access up to  4% APY returns
    //They will have 0 tax when they claim at the end of the 90 Days stake.They can't claim before that
    //They can make up to 300 % return on their stake amount. 
  }


  const stake180Days = function (){


    //When users click on 180 Days button, they will bake and stake for 180 days. 
    //They will access up to 6 % APY returns
    //They will have 0 tax when they claim at the end of the 180 Days stake.They can't claim before that
    //They can make up to 400 % return on their stake amount. 
  }
  

  const stake365Days = function (){


    //When users click on 365 Days button, they will bake and stake for 365 days. 
    //They will access up to 8 % APY returns
    //They will have 0 tax when they claim at the end of the 365 Days stake. They can't claim before that
    //They can make up to 500 % return on their stake amount. 
  }
  
  
  

  // const bake = async () => {

  //   console.log('the function is working!');
  //   setLoading(true);
  //   // pauseVideo();
  //   // win.postMessage("pauseVideo", theURL);
  //   var video = document.getElementById("video1");
  //   if (video.paused){video.play();} else {video.pause();}

  //   // myVideo.play();

  //   // if (myVideo.paused)
  //   //   myVideo.play();
  //   // else
  //   //   myVideo.pause();

  //   const ref = getRef();

  //   // try {
  //   //   await contract.methods.buyEggs(ref).send({
  //   //     from: address,
  //   //     value: toWei(`${bakeBNB}`),
  //   //   });
  //   // } catch (err) {
  //   //   console.error(err);
  //   // }
  //   // fetchWalletBalance();
  //   // fetchContractBNBBalance();
  //   setLoading(false);
  // };

  const reBake = async () => {
    setLoading(true);

    const ref = getRef();

    try {
      await contract.methods.hatchEggs(ref).send({
        from: address,
      });
    } catch (err) {
      console.error(err);
    }
    fetchWalletBalance();
    fetchContractBNBBalance();
    setLoading(false);
  };

  const eatBeans = async () => {
    setLoading(true);

    try {
      await contract.methods.sellEggs().send({
        from: address,
      });
    } catch (err) {
      console.error(err);
    }
    fetchWalletBalance();
    fetchContractBNBBalance();
    setLoading(false);
  };




  // console.log('win: ', win);

  // function playVideo() {
  //   console.log('theURL: ', theURL);

  // }

  // function pauseVideo() {
  //   win.postMessage("pauseVideo", theURL);
  // }


      const [isStakerApp, setIsStakerApp] = React.useState(false);

      React.useEffect(() => {
        const timer = setInterval(() => {
          setIsStakerApp((prev) => !prev);
        }, 3000);
        return () => clearInterval(timer);
      }, []);

      const [stakingPeriod, setStakingPeriod] = useState(0); // Default staking period in days
      const [stakedValue, setStakedValue] = useState(0);
      const [rewards, setRewards] = useState(0);
    
      const handleStakingPeriodChange = (event) => {
        const selectedStakingPeriod = parseInt(event.target.value);
        setStakingPeriod(selectedStakingPeriod);
    
        // Update rewards based on stakedValue and staking period
        const calculatedRewards = calculateRewards(stakedValue, selectedStakingPeriod);
        setRewards(calculatedRewards);
      };
    
      const handleStakedValueChange = (event) => {
        const newStakedValue = parseFloat(event.target.value);
        setStakedValue(newStakedValue);
    
        // Update rewards based on stakedValue and staking period
        const calculatedRewards = calculateRewards(newStakedValue, stakingPeriod);
        setRewards(calculatedRewards);
      };
    
      const calculateRewards = (value, period) => {
        const apy = period * 4000 / 365; // Varying APY from 0% to 400%
        const stakingDurationInYears = period / 365;
        const reward = value * (Math.pow(1 + apy / 100, stakingDurationInYears) - 1);
        return reward.toFixed(2);
      };
    
  return (
    <div style={{ textAlign: "center" }}>
        {/* <img src={AMP} width="400" height="250"/> */}
    
       
        {/* <img src={AMPL1} width="200" height=""/> */}
        <br></br>
        <img className='bouncing-image' alt='Bouncing Image' src={s} width="200" height=""/>
        <div className="text-center">
          

        <div
        style={{
          fontWeight: 'bold',
          fontSize: '37px',
          color: 'green',
          marginTop: '10px',
          marginBottom: '5px',
          marginLeft: '10px',
          fontFamily: 'Racing Sans One',
          position: 'relative',
          display: 'inline-block',
        }}
      > <h1 style={{ fontSize: '50px'}}>Chilli</h1>

        <h3 style={{color: 'white', fontSize: '23px', fontWeight: 'bold'}}>AVAX MINER</h3>
        <h4 style={{color: 'white', fontSize: '23px'}}>ITs <strong style={{ fontWeight: 'bold',color:'yellow' }}>HOT</strong> USE WITH CAUTION ⚠️ </h4>
        {/* <span className="light-effect"></span> */}
        {/* {isStakerApp ? (
          <span style={{ color: 'Red' }}>Strawberry</span>
        ) : 
          // <span  style={{ color: 'white'}}></span>
        } */}
      </div>
</div>
     


     
      <Wrapper>
        {/* <Gif ref={(gif) => this.currentGif = gif} /> */}
        {/* {loading ? <Logo src={logoGif} ref={vidRef} id="video1" alt="site logo" /> : <Logo src={logoPng} ref={vidRef} id="video1" alt="site logo" />} */}
        
        {/* <video id="video1" ref={vidRef} src={logoVideo} width={"100%"} type="video/mp4"></video> */}
        {/* <div style={{ width: "100%", height: "0", paddingBottom: "36%", position: "relative" }}>
          <iframe src="https://giphy.com/embed/RLj49U7KXsW9SmrLB6" width="100%" style={{ border: "100px" }} allowFullScreen>
          </iframe>
        </div> */}
        <Connect responsive={false} />
        <Typography variant="h6" marginTop={-3} textAlign={'center'}>
          <br></br>
          {/* <p> The First Proof of Stake Miner on PulseChain for <strong>$PLS</strong> with the highest daily return and lowest dev fee</p> */}
        </Typography>
      </Wrapper>
      <div>

      {/* <div className="boxContainer">
      <div className="box">
        <h2>Option 1: Save ⛽  </h2>
        <p>
          For best returns you can amplify 1 time and claim on day 10. 
          This will save your PLS from being wasted on gas fees.
        </p>
      </div>
      <div className="box">
        <h2>Option 2:⛽ doesn't matter 🏎️ </h2>
        <p>
          For more miners you can amplify 1 time per day and claim once every 10 days.
        </p>
      </div>
    </div> */}

     

        {/* <p>
          <a href="https://giphy.com/gifs/transparent-RLj49U7KXsW9SmrLB6">
            via GIPHY
          </a>
        </p>  */}
      </div>
      <CardWrapper className="wrapper">
        {loading && <LinearProgress color="secondary" />}

        <CardContent>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            mt={3}
          >
            <Typography variant="body1">Contract</Typography>
            <Typography variant="h5">{contractBNB} AVAX</Typography>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            mt={3}
          >
            <Typography variant="body1">Wallet</Typography>
            <Typography variant="h5">{walletBalance.bnb} AVAX</Typography>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            mt={3}
          >
            <Typography variant="body1">Your seeds</Typography>
            <Typography variant="h5">{walletBalance.beans} </Typography>
          </Grid>
          
          <Box paddingTop={4} paddingBottom={3}>
            <Box className=''>
              <PriceInput
                max={+walletBalance.bnb}
                value={bakeBNB}
                onChange={(value) => onUpdateBakeBNB(value)}
              />
            </Box>
            
            <Box marginTop={3} marginBottom={3}>
              <Button
                
                variant="contained"
                fullWidth
                className="mybutton"
                disabled={wrongNetwork || !address || +bakeBNB === 0 || loading}
                onClick={bake}
                
              >
                Stake 
              </Button>
            </Box>

            
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <Typography variant="body1" fontWeight="bolder">
                Your Rewards
              </Typography>
              <Typography variant="h5" fontWeight="bolder">
                {walletBalance.rewards} AVAX
              </Typography>
            </Grid>
            <ButtonContainer container>
              <Grid item flexGrow={1} marginRight={1} marginTop={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className="mybutton"
                  disabled={wrongNetwork || !address || loading}
                  onClick={reBake}
                >
                  Compound
                </Button>
              </Grid>
              <Grid item flexGrow={1} marginLeft={1} marginTop={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className="mybutton"
                  disabled={wrongNetwork || !address || loading}
                  onClick={eatBeans}
                >
                  Claim
                </Button>
              </Grid>



            </ButtonContainer>
          </Box>
          <Divider />


            <div>
              <h3></h3>
            </div>



            {/* <Box marginTop={3} marginBottom={3}>
              <Button
                variant="contained"
                fullWidth
                className="mybutton"
                disabled={wrongNetwork || !address || +bakeBNB === 0 || loading}
                onClick={bake}
              >
                Stake 90 Days 
              </Button>
            </Box>
            <Box marginTop={3} marginBottom={3}>
              <Button
                variant="contained"
                fullWidth
                className="mybutton"
                disabled={wrongNetwork || !address || +bakeBNB === 0 || loading}
                onClick={bake}
              >
               Stake 180 Days 
              </Button>
            </Box>
            <Box marginTop={3} marginBottom={3}>
              <Button
                variant="contained"
                fullWidth
                className="mybutton"
                disabled={wrongNetwork || !address || +bakeBNB === 0 || loading}
                onClick={bake}
              >
               Stake 365 Days
              </Button>
            </Box> */}
             



{/* 
    <div>
      <h2>Staking Rewards Calculator</h2>
      <div>
        <label>Staking Period (days): {stakingPeriod}</label>
        <input
          type="range"
          min="0"
          max="365"
          step="1"
          value={stakingPeriod}
          onChange={handleStakingPeriodChange}
        />
      </div>
      <div>
        <label>Staked Value: {stakedValue}</label>
        <input
          type="number"
          step="0.01"
          value={stakedValue}
          onChange={handleStakedValueChange}
        />
      </div>
      <div>
        <p>Potential Rewards: {rewards} tokens</p>
      </div>
    </div> */}


        </CardContent>
      </CardWrapper>
    </div>
  );
}
