// import Box from "@mui/material/Box";
import { BrowserRouter } from "react-router-dom";
import Home from "./Home";
// import Header from "./Home/components/Header";


function App() {
  return (
    <BrowserRouter>
    
 
    <Home />
    </BrowserRouter>
  );
}

export default App;
