// // //This is mainnet AVAX
export const config = {
  chainId: 43114,
  contractAddress:"0x3D3b4a32C27eefbC0098846D7C0F8be37f52d6a2",
  scanLink:"https://snowtrace.io/address/0x3D3b4a32C27eefbC0098846D7C0F8be37f52d6a2",
};

// This is mainnet PLS Peach
// export const config = {
//   chainId: 369,
//   contractAddress:"0x700881A40281f33a5Baa1c45bDef602316380A20",
//   scanLink:"https://scan.mypinata.cloud/ipfs/bafybeidn64pd2u525lmoipjl4nh3ooa2imd7huionjsdepdsphl5slfowy/#/address/0x700881A40281f33a5Baa1c45bDef602316380A20",
// };




//0x0a2ad1CFF67482D9718b2dA8E31698DFF8f5Fa8a BNB Amplifier version
//https://testnet.bscscan.com/address/0x0a2ad1cff67482d9718b2da8e31698dff8f5fa8a


//0x757E2763b6E420f5fF347517d2189Ba0DeACceB5 PLS Amplifier version 

